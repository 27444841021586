import $ from "jquery";

export const initJqueryHightlightText = () => {
  $.fn.highlight = function(pat) {
    function innerHighlight(node, pat) {
      var skip = 0;
      if (node.nodeType == 3) {
        var pos = node.data.toUpperCase().indexOf(pat);
        if (pos >= 0) {
          var spannode = document.createElement("span");
          spannode.className = "highlight";
          var middlebit = node.splitText(pos);
          var endbit = middlebit.splitText(pat.length);
          var middleclone = middlebit.cloneNode(true);
          spannode.appendChild(middleclone);
          middlebit.parentNode.replaceChild(spannode, middlebit);
          skip = 1;
        }
      } else if (
        node.nodeType == 1 &&
        node.childNodes &&
        !/(script|style)/i.test(node.tagName)
      ) {
        for (var i = 0; i < node.childNodes.length; ++i) {
          i += innerHighlight(node.childNodes[i], pat);
        }
      }
      return skip;
    }
    return this.each(function() {
      innerHighlight(this, pat.toUpperCase());
    });
  };

  $.fn.removeHighlight = function () {
    function newNormalize(node) {
      var i = 0;
      while (i < node.childNodes.length) {
        var child = node.childNodes[i];
        if (child.nodeType == 1) {
          newNormalize(child);
          i++;
        } else if (child.nodeType == 3) {
          var next = child.nextSibling;
          while (next != null && next.nodeType == 3) {
            child.nodeValue += next.nodeValue;
            node.removeChild(next);
            next = child.nextSibling;
          }
          i++;
        } else {
          i++;
        }
      }
    }

    return this.find("span.highlight")
      .each(function () {
        var thisParent = this.parentNode;
        var firstChild = this.firstChild;
        if (this.nextSibling && this.nextSibling.nodeType == 3) {
          // Если следующий узел текстовый, объедините тексты
          firstChild.nodeValue += this.nextSibling.nodeValue;
          thisParent.removeChild(this.nextSibling);
        }
        var highlightText = firstChild.nodeValue;
        var new_node = document.createTextNode(highlightText);
        thisParent.replaceChild(new_node, this);
        newNormalize(thisParent);
      })
      .end();
  };
};
