import $ from 'jquery';
import { bindJQuery } from '@/scripts/helpers/library';
import { addedLazyLoadImages } from '@/scripts/helpers/lazyload';
import { commonLegacy } from '@/scripts/helpers/commonLegacy';
import 'slick-carousel/slick/slick';
import { BREAKPOINTS } from '@/scripts/helpers/constants';
import { initUiDropdown } from '@/modules/containers/docprep-select-widget/ui-dropdown/ui-dropdown-select';
import { initJqueryHightlightText } from '@/modules/containers/docprep-select-widget/script-includes/highlight';
import axios from 'axios';

const { TABLET_XL, DESKTOP } = BREAKPOINTS;
//	DO NOT COPY JS
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const { type } = params || {};
const mobileTrigger = $('.mobile-menu__trigger');
const mobileTriggerClose = $('.mobile-menu__close');
const mobileMenu = $('.mobile-menu');
const selectStateItemSelector = $('#bannerStateDropdown .ui-dropdown-select__link');
const currentState = $('#bannerStateDropdown .ui-dropdown-select__value span');
const IS_VISIBLE = 'is-visible';
const statesRequestUrl = window.statesRequest;
const locationRequestUrl = 'https://ipapi.co/json/';
let states = [
  {
    label: 'No results found.',
    value: 'notFound',
    stateValue: '',
  },
  {
    label: 'Alabama',
    value: 'al',
  },
  {
    label: 'Alaska',
    value: 'ak',
  },
  {
    label: 'Arizona',
    value: 'az',
  },
  {
    label: 'Arkansas',
    value: 'ar',
  },
  {
    label: 'California',
    value: 'ca',
  },
  {
    label: 'Colorado',
    value: 'co',
  },
  {
    label: 'Connecticut',
    value: 'ct',
  },
  {
    label: 'Delaware',
    value: 'de',
  },
  {
    label: 'District of Columbia',
    value: 'dc',
  },
  {
    label: 'Florida',
    value: 'fl',
  },
  {
    label: 'Georgia',
    value: 'ga',
  },
  {
    label: 'Hawaii',
    value: 'hi',
  },
  {
    label: 'Idaho',
    value: 'id',
  },
  {
    label: 'Illinois',
    value: 'il',
  },
  {
    label: 'Indiana',
    value: 'in',
  },
  {
    label: 'Iowa',
    value: 'ia',
  },
  {
    label: 'Kansas',
    value: 'ks',
  },
  {
    label: 'Kentucky',
    value: 'ky',
  },
  {
    label: 'Louisiana',
    value: 'la',
  },
  {
    label: 'Maine',
    value: 'me',
  },
  {
    label: 'Maryland',
    value: 'md',
  },
  {
    label: 'Massachusetts',
    value: 'ma',
  },
  {
    label: 'Michigan',
    value: 'mi',
  },
  {
    label: 'Minnesota',
    value: 'mn',
  },
  {
    label: 'Mississippi',
    value: 'ms',
  },
  {
    label: 'Missouri',
    value: 'mo',
  },
  {
    label: 'Montana',
    value: 'mt',
    selected: true,
  },
  {
    label: 'Nebraska',
    value: 'ne',
  },
  {
    label: 'Nevada',
    value: 'nv',
  },
  {
    label: 'New Hampshire',
    value: 'nh',
  },
  {
    label: 'New Jersey',
    value: 'nj',
  },
  {
    label: 'New Mexico',
    value: 'nm',
  },
  {
    label: 'New York',
    value: 'ny',
  },
  {
    label: 'North Carolina',
    value: 'nc',
  },
  {
    label: 'North Dakota',
    value: 'nd',
  },
  {
    label: 'Ohio',
    value: 'oh',
  },
  {
    label: 'Oklahoma',
    value: 'ok',
  },
  {
    label: 'Oregon',
    value: 'or',
  },
  {
    label: 'Pennsylvania',
    value: 'pa',
  },
  {
    label: 'Rhode Island',
    value: 'ri',
  },
  {
    label: 'South Carolina',
    value: 'sc',
  },
  {
    label: 'South Dakota',
    value: 'sd',
  },
  {
    label: 'Tennessee',
    value: 'tn',
  },
  {
    label: 'Texas',
    value: 'tx',
  },
  {
    label: 'Utah',
    value: 'ut',
  },
  {
    label: 'Vermont',
    value: 'vt',
  },
  {
    label: 'Virginia',
    value: 'va',
  },
  {
    label: 'Washington',
    value: 'wa',
  },
  {
    label: 'West Virginia',
    value: 'wv',
  },
  {
    label: 'Wisconsin',
    value: 'wi',
  },
  {
    label: 'Wyoming',
    value: 'wy',
  },
];
let templates = [
  {
    label: 'No results found.',
    value: 'notFound',
  },
];

const updateDocumentUrl = () => {
  const docprepUrl = window.docprepHost; // 'https://www.bravo.uslegalforms-labs.xyz/sso/docprep/';
  const refUrl = window.refHost; // 'https://mfrontlab-03.tools.msites-dev.xyz';
  const state = currentState.text().replace(/ /g, '-');
  let formId = '';

  if (window.docprepTemplate) {
    formId = window.docprepTemplate;
  } else {
    formId = $('#bannerTemplateDropdown .ui-dropdown-select__value').attr('data-value');
  }

  return `${docprepUrl}?form_id=${formId}&state=${state}&ref=${refUrl}`;
};

const showTemplates = (isShow) => {
  const templatesList = $('#bannerTemplateDropdown > .ui-dropdown-select__wrapper');

  if (isShow) templatesList.removeClass('is-disabled');
  else templatesList.addClass('is-disabled');
};

const isStateSelected = (array) => {
  $.each(array, (key, item) => {
    if (item?.selected) {
      currentState.html(item?.label);
      showTemplates(true);
    }
  });
};

const removeData = (array = null, parent) => {
  array = [];
  parent.empty();
};

const updateData = (array, parent) => {
  templates = array;

  $.each(array, (key, item) => {
    parent
      .append(
        `<li class='ui-dropdown-select__item'>
            <div class='ui-dropdown-select__link ${item?.selected ? 'current' : ''}' data-value='${item?.value}'>${item?.label}</div>
         </li>`,
      );
  });
};

const sendApiRequest = (method, url, body = null) => {
  let result = null;

  if (method === 'GET') {
    axios.get(url)
      .then(response => {
        console.log(JSON.parse(JSON.stringify(response)).data.geoplugin_region);
      })
      .catch(error => { console.log('GET API error: ', error); });
  } else if (method === 'POST') {
    axios.post(url, body)
      .then(response => {
        const templatesContainer = $('#bannerTemplateDropdown .ui-dropdown-select__list');
        const statesContainer = $('#bannerStateDropdown .ui-dropdown-select__list');
        result = JSON.parse(JSON.stringify(response));
        states = result.data.states;

        isStateSelected(states);

        removeData(states, statesContainer);
        removeData(templates, templatesContainer);

        updateData(result.data.states, statesContainer);
        updateData(result.data.templates, templatesContainer);
      })
      .catch(error => {
        console.log('Parent POST method API error: ', error);
      });
  }
};

$(document).on('click', '#bannerStateDropdown .ui-dropdown-select__link', function () {
  /* const selectedStateValue = $(this).attr("data-value"); */
  /* const selectedState = $(this).html(); */
  const selectedState = $(this).text();

  const body = {
    site: window.site, // 7707
    state: selectedState,
  };

  sendApiRequest('POST', statesRequestUrl, body);
});

const setDocumentUrlOnSelectStateAndTemplate = () => {
  // Global selectors & values
  const createDocumentSubmitSelector = $('#createDocumentSubmit');
  const stateSelector = $('#bannerStateDropdown');
  const templateSelector = $('#bannerTemplateDropdown');
  const docPrepContainerSelector = $('.docprep-banner__create-document');

  if (!stateSelector.length) {
    templateSelector
      .find('.ui-dropdown-select__wrapper')
      .removeClass('is-disabled');
  }

  createDocumentSubmitSelector.on('click', function (e) {
    // Local constant values
    const staticStateValue = docPrepContainerSelector.attr('data-state');
    const staticTemplateValue = docPrepContainerSelector.attr('data-template');
    let stateDropdownValue = $(this)
      .closest('.docprep-banner__create-document')
      .find('#bannerStateDropdown .ui-dropdown-select__value span')
      .text();

    stateDropdownValue = staticStateValue || stateDropdownValue.replace(/\s+/g, '-').toLowerCase();

    let templateDropdownValue = $(this)
      .closest('.docprep-banner__create-document')
      .find('#bannerTemplateDropdown .ui-dropdown-select__value')
      .attr('data-value');
    templateDropdownValue = staticTemplateValue || templateDropdownValue;

    const isTemplateSelectDisabled = templateSelector
      .find('.ui-dropdown-select__wrapper')
      .hasClass('is-disabled');

    let validationStatus;
    if (stateDropdownValue !== 'select-state' && templateDropdownValue) {
      validationStatus = 'success';
    } else {
      validationStatus = 'failed';
    }

    // Depends on condition of validationStatus creates full url or added errors on select
    if (validationStatus === 'success') {
      stateSelector.removeClass('has-error');
      templateSelector.removeClass('has-error');

      console.log(updateDocumentUrl());
      $(this).attr('href', updateDocumentUrl());
    } else {
      if (stateDropdownValue === 'select-state') {
        stateSelector.addClass('has-error');
      } else {
        stateSelector.removeClass('has-error');
      }
      if (templateDropdownValue) {
        templateSelector.removeClass('has-error');
      } else if (!isTemplateSelectDisabled) {
        templateSelector.addClass('has-error');
      }
      e.preventDefault();
    }
  });

  // On handle change {template} selected value, validate current select for any errors
  $(document).on('click', '#bannerTemplateDropdown .ui-dropdown-select__link', function () {
    const staticTemplateValue = docPrepContainerSelector.attr('data-template');

    setTimeout(() => {
      let templateDropdownValue = $(this)
        .closest('.docprep-banner__create-document')
        .find('#bannerTemplateDropdown .ui-dropdown-select__value')
        .attr('data-value');

      const templateDropdownActiveElement = templateSelector.find('.ui-dropdown-select__value');

      templateDropdownValue = staticTemplateValue || templateDropdownValue;

      templateDropdownActiveElement.removeClass('active');
      templateDropdownActiveElement.addClass('active');

      if (!templateDropdownValue) {
        templateSelector.addClass('has-error');
      } else {
        templateSelector.removeClass('has-error');
      }
    }, 100);
  });
};

/*
 * On change state fetch list of forms that connected to selected state
 * then clear current <ul> list and append all new forms to it.
 *  */
const updateListOfFormsOnChangeState = () => {
  const templateSelector = $('#bannerTemplateDropdown');

  selectStateItemSelector.on('click', async () => {
    templateSelector
      .find('.ui-dropdown-select__wrapper')
      .addClass('is-disabled');

    const formsListSelector = $('#bannerTemplateDropdown .ui-dropdown-select__list');

    if (templates) {
      formsListSelector.empty();
      templateSelector
        .find('.ui-dropdown-select__wrapper')
        .removeClass('is-disabled');
    }
  });
};

if (mobileMenu) {
  mobileTrigger.on('click', () => {
    mobileMenu.addClass(IS_VISIBLE);
    $('body').addClass('is-mobile-menu-visible');
  });

  mobileTriggerClose.on('click', () => {
    mobileMenu.removeClass(IS_VISIBLE);
    $('body').removeClass('is-mobile-menu-visible');
  });
}

const showMore = () => {
  if ($('.js-show-more').length) {
    $('.js-show-more').on('click', function () {
      $(this).parent().toggleClass('is-open');
      const hiddenContent = $(this).parent().prev();
      const innerHeight = hiddenContent.find('.section-content__inner').outerHeight();
      hiddenContent.toggleClass('is-open');

      if (hiddenContent.hasClass('is-open')) {
        hiddenContent.css({ height: innerHeight });
      } else {
        hiddenContent.removeAttr('style');
      }
    });
  }
};

const initOtherFormsSlider = () => {
  const otherFormsSlider = $('.js-other-forms-slider');
  const prevArrow = `
    <button type="button" class="slider-arrow slider-arrow--prev">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" fill="none" stroke="#22C29A"/>
        <path id="Path 4" d="M23.6055 18L17.6055 24L23.6055 30" stroke="#22C29A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path 5" d="M17.6055 24H29" stroke="#22C29A" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </button>
  `;
  const nextArrow = `
    <button type="button" class="slider-arrow slider-arrow--next">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="23.5" stroke="#22C29A"/>
        <path id="Path 4" d="M24.3945 18L30.3945 24L24.3945 30" stroke="#22C29A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path 5" d="M30.3945 24H19" stroke="#22C29A" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </button>
  `;

  if (otherFormsSlider.length) {
    otherFormsSlider.slick({
      infinite: true,
      focusOnSelect: false,
      pauseOnFocus: false,
      arrows: true,
      adaptiveHeight: true,
      prevArrow,
      nextArrow,
      cssEase: 'linear',
      variableWidth: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: TABLET_XL,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
          },
        },
        {
          breakpoint: DESKTOP,
          settings: {
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    });
  }
};

const setSmoothAnchorsScroll = () => {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        && location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        let target = $(this.hash);
        target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top,
          }, 1000, () => {
            // Callback after animation
            // Must change focus!
            const $target = $(target);
            $target.focus();
            if ($target.is(':focus')) { // Checking if the target was focused
              return false;
            }
            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          });
        }
      }
    });
};

$(document).ready(() => {
  addedLazyLoadImages();
  bindJQuery();
  commonLegacy();
  showMore();
  initOtherFormsSlider();
  setSmoothAnchorsScroll();

  $('#selectRegularSearchInput').on('keyup', () => {
    if ($('#selectRegularSearchInput').val().length < 1) {
      $('.ui-dropdown-select__link').removeHighlight();
    }
  });

  const stateDropdown = $('#bannerStateDropdown');

  if (stateDropdown.length > 0) {
    const accordion = $('.js-accordion .accordion-item');
    const accordionItemTitle = $('.js-accordion .accordion-item__head');

    // legacy accordion
    function openAccordion() {
      const animationTime = 0;
      accordion.not($(this)).removeClass('is-active');
      $(this).toggleClass('is-active');
      accordion
        .not($(this))
        .find('.accordion-item__body')
        .slideUp(animationTime);
      $(this)
        .find('.accordion-item__body')
        .slideToggle(animationTime);
    }

    accordionItemTitle.on('focus', (e) => {
      accordionItemTitle.removeAttr('style');
    });

    accordion.on('click', function (e) {
      $(e.target).css('outline', '0');
      openAccordion.call(this);
    });

    accordion.on('keydown', (e) => {
      if (e.key === 'Enter') {
        openAccordion.call(e.target.parentNode);
      }
    });

    axios.get(locationRequestUrl)
      .then(response => {
        const result = JSON.parse(JSON.stringify(response)).data.region;
        let state = '';

        if (result) state = result;

        const body = {
          site: window.site, // 7707
          state,
        };

        sendApiRequest('POST', statesRequestUrl, body);
      })
      .catch(error => {
        console.log('Location API request error: ', error);

        const body = {
          site: window.site, // 7707
          state: '',
        };

        sendApiRequest('POST', statesRequestUrl, body);
      });

    initUiDropdown();
    initJqueryHightlightText();
    setDocumentUrlOnSelectStateAndTemplate();
    updateListOfFormsOnChangeState();
  }

  const selector = {
    loaderTrigger: '[data-loader]',
    percentage: '.percentage',
    loader: '.js-loader',
    circle: '.loader .circle',
  };

  function showLoader() {
    $(selector.loader).addClass('is-show');
  }

  function animateCount(el) {
    $(el).each(function () {
      const $this = $(this);
      $({ Counter: 0 }).animate({
        Counter: $this.text(),
      }, {
        duration: 2800,
        easing: 'swing',
        step: function stepFn() {
          $this.text(`${Math.ceil(this.Counter)}%`);
        },
      });
    });
  }

  function animateCircle(el) {
    $(el).each(function () {
      const $this = $(this);
      $({ Counter: 100 }).animate({
        Counter: $this.attr('stroke-dashoffset'),
      }, {
        duration: 2800,
        easing: 'swing',
        step: function stepFn() {
          $this.attr('stroke-dashoffset', this.Counter);
        },
      });
    });
  }

  $(selector.loaderTrigger).on('click', (e) => {
    e.preventDefault();

    if ($(selector.loader).length) { // if loader markup exist in DOM
      showLoader();
      animateCircle(selector.circle);
      animateCount(selector.percentage);
      $('body').addClass('modal-open');
      $('body').keyup((evt) => {
        if (evt.keyCode === 27 || evt.which === 27) {
          evt.preventDefault();
        }
      });
    }
  });

  window.addEventListener('pageshow', (evt) => {
    if (evt.persisted) {
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }
  }, false);

  function showDetails() {
    $('.js-section-details').toggleClass('is-open');
  }

  $('.js-details-show, .js-details-hide').on('click', showDetails);
  $('.js-details-show, .js-details-hide').on('keydown', (e) => {
    if (e.key === 'Enter') {
      showDetails();
    }
  });

  const initBenefitFromCarousel = () => {
    const benefitFromCarousel = $('.js-benefit-from-carousel');
    const windowWidth = $(window).width();
    const destroyBreakpoint = 830;

    if (windowWidth > destroyBreakpoint) {
      if (benefitFromCarousel.hasClass('slick-initialized')) {
        benefitFromCarousel.slick('unslick');
      }
    } else if (benefitFromCarousel.length) {
      benefitFromCarousel.not('.slick-initialized').slick({
        dots: true,
        arrows: false,
        cssEase: 'linear',
        speed: 150,
        autoplaySpeed: 4000,
        autoplay: false,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        pauseOnHover: true,
        pauseOnFocus: false,
        pauseOnDotsHover: !(
          !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        ),
      });
    }
  };
  $(window).on('load resize', initBenefitFromCarousel);
});
