import $ from "jquery";
import { CLASSES } from "@/scripts/helpers/constants";

// Move this to loader.js
export const initLoader = selector => {
  // Possible to have several loaders
  // If no selector passed on, find closest and use it globally
  const loader = selector || $("." + CLASSES.LOADER_WRAPPER);
  const loaderWrapper = loader.closest("." + CLASSES.LOADER_WRAPPER);

  if (loader.length) {
    if (!loaderWrapper.length) {
      $("body").addClass(CLASSES.LOADER_WRAPPER);
    }
    loader.addClass(CLASSES.IS_ACTIVE);
  }
};

export const hideLoader = () => {
  const loader = $("." + CLASSES.LOADER);
  const document = $("body");

  if (loader.hasClass(CLASSES.IS_ACTIVE)) {
    loader.removeClass(CLASSES.IS_ACTIVE);
  }

  if (document.hasClass(CLASSES.LOADER_WRAPPER)) {
    document.removeClass(CLASSES.LOADER_WRAPPER);
  }
};

export const getScrollBarWidth = function() {
  const inner = document.createElement("p");
  inner.style.width = "100%";
  inner.style.height = "200px";

  const outer = document.createElement("div");
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);
  const w1 = inner.offsetWidth;
  outer.style.overflow = "scroll";
  let w2 = inner.offsetWidth;
  if (w1 === w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return w1 - w2;
};

export const bindJQuery = function() {
  window.$ = window.JQuery = window.jQuery = $;

  $.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: ns.indexOf("noPreventDefault") === -1 });
    }
  };
  $.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: ns.indexOf("noPreventDefault") === -1 });
    }
  };
  $.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
  };
  $.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
  };
};

export const attachIE11helper = () => {
  if (navigator.userAgent.match(/Trident.*rv:11\./))
    $("body").addClass("is-ie11");
};

export const showFunctionalityLinks = () => {
  $(".js-functionality-links-button").on("click", function() {
    const self = $(this);
    const viewMoreModifier = "functionality-links__button--view-more";
    const viewLessModifier = "functionality-links__button--view-less";
    const categoryElem = ".functionality-links__category";
    const itemElem = ".functionality-links__item";
    const viewMoreText = "View more";
    const viewLessText = "View less";
    const maxItems = 4;

    if (self.hasClass(viewMoreModifier)) {
      self
        .removeClass(viewMoreModifier)
        .addClass(viewLessModifier)
        .text(viewLessText)
        .parents(categoryElem)
        .find(itemElem)
        .each(function(i, item) {
          $(item).removeClass(CLASSES.IS_HIDDEN);
        });
    } else if ($(this).hasClass(viewLessModifier)) {
      self
        .removeClass(viewLessModifier)
        .addClass(viewMoreModifier)
        .text(viewMoreText)
        .parents(categoryElem)
        .find(itemElem)
        .each(function(i, item) {
          if (i + 1 > maxItems) {
            $(item).addClass(CLASSES.IS_HIDDEN);
          }
        });
    }
  });
};
