import { debounce } from "lodash";
import $ from "jquery";

/**
 * Initial ui dropdown select variables
 */
const searchInputSelector = $(".js-handle-search-input");
const clearSearchResultButtonSelector = $(".js-clear-result-filter");
const pageSelector = $(".layout-responsive__body");
const dropdownWrapperSelector = $(".ui-dropdown-select__wrapper");
const dropdownLinkSelector = $(".ui-dropdown-select__link");
const selectedOptionRedirectLinkSelector = $(".ui-modal__action-btn-redirect");
const handleCloseModalSelector = $(".js-modal-close");
const handleOptionsDropdownSelector = $(".js-handle-options-dropdown");

/**
 * Initial change ui dropdown select constants
 */
const actionClasses = {
  visible: "is-visible",
  active: "is-active",
  animateSlideUp: "animate-slide-up",
  animateSlideHr: "animate-slide-hr",
  selected: "current"
};

const jsClasses = {
  handleOptionsDropdown: "js-handle-options-dropdown"
};

const hideDoesntMatchFilterItemsHandler = function(
  allOptionsSelector,
  searchedValue
) {
  allOptionsSelector
    .css("display", "")
    .filter(function() {
      return (
        $(this)
          .children("div, a")
          .eq(0)
          .text()
          .toLowerCase()
          .indexOf(searchedValue.toLowerCase()) === -1
      );
    })
    .hide();

  allOptionsSelector.each(function() {
    if ($(this).is(":visible")) {
      const currentStateLink = $(this).find("div, a");

      // remove any old highlighted terms
      currentStateLink.removeHighlight();

      // disable highlighting if empty
      if (searchedValue.toLowerCase()) {
        // highlight the new term
        currentStateLink.highlight(searchedValue.toLowerCase());
      }
    }
  });

  if ($(".js-menu-for-filter .ui-dropdown-select__item").is(":visible")) {
    $(".ui-dropdown-select__item--not-found").css("display", "none");
  } else {
    $(".ui-dropdown-select__item--not-found").css("display", "block");
  }
};

const changeOptionsFilter = () => {
  // Filter box
  searchInputSelector.on("keyup", function() {
    const searchedValue = $(this).val();
    const currentSearchOptionsSelector = $(this)
      .closest(".ui-dropdown-select")
      .find(".js-menu-for-filter li");



    if (searchedValue.length > 0) {
      hideDoesntMatchFilterItemsHandler(
        currentSearchOptionsSelector,
        searchedValue
      );

      clearSearchResultButtonSelector.addClass(actionClasses.visible);
    } else {
      $('.ui-dropdown-select__item').css("display", "block");
      dropdownLinkSelector.removeHighlight();
      clearSearchResultButtonSelector.removeClass(actionClasses.visible);
    }
  });

  clearSearchResultButtonSelector.on("click", () => {
    searchInputSelector.val("");
    const searchedValue = "";

    if (searchedValue.length > 0) {
      hideDoesntMatchFilterItemsHandler();
    } else {
      clearSearchResultButtonSelector.removeClass(actionClasses.visible);
      $('.ui-dropdown-select__item').css("display", "block");
      dropdownLinkSelector.removeHighlight();
    }
  });
};

export const initUiDropdown = () => {
  console.log("initUiDropdown");

  handleOptionsDropdownSelector.on("click", function(e) {
    e.preventDefault();
    const currentDropdownWrapper = $(this).closest(
      ".ui-dropdown-select__wrapper"
    );
    const currentDropdownListSelector = $(this)
      .closest(".ui-dropdown-select__wrapper")
      .find(".ui-dropdown-select__list");
    const currentDropdownWrapperIsActive = $(this)
      .closest(".ui-dropdown-select__wrapper")
      .hasClass(actionClasses.active);

    currentDropdownListSelector.scrollTop(0);
    if (currentDropdownWrapperIsActive) {
      $(".ui-dropdown-select__wrapper").removeClass(actionClasses.active);
      currentDropdownWrapper
        .removeClass(actionClasses.active)
        .closest(".ui-modal")
        .removeClass(actionClasses.animateSlideUp);
    } else {
      $(".ui-dropdown-select__wrapper").removeClass(actionClasses.active);
      currentDropdownWrapper
        .addClass(actionClasses.active)
        .closest(".ui-modal")
        .addClass(actionClasses.animateSlideUp);

      setTimeout(() => {
        let currentActiveStatePositionToParent = $(this)
          .closest(".ui-dropdown-select__wrapper")
          .find(".current")
          .parent()
          .position();

        if (currentActiveStatePositionToParent !== undefined) {
          currentActiveStatePositionToParent = currentActiveStatePositionToParent.top;
        }

        if (currentActiveStatePositionToParent > 0) {
          currentDropdownListSelector.animate(
            {
              scrollTop: currentActiveStatePositionToParent
            },
            300
          );
        }
      }, 0);
    }

    if (window.matchMedia("(max-width: 960px)").matches) {
      $("body")
        .toggleClass("is-ui-select-list")
        .toggleClass("no-scrollable");
    }
  });

  $(document).click(function(e) {
    var $tgt = $(e.target);
    if (
      !$tgt.closest(".ui-dropdown-select__search-item").length &&
      !$tgt.hasClass(jsClasses.handleOptionsDropdown)
    ) {
      if (!window.matchMedia("(max-width: 960px)").matches) {
        dropdownWrapperSelector.removeClass(actionClasses.active);
      }
    }
  });

  const showTemplates = (isShow) => {
    const templatesList = $('#bannerTemplateDropdown > .ui-dropdown-select__wrapper');

    if (isShow) templatesList.removeClass('is-disabled');
    else templatesList.addClass('is-disabled');
  }

  // Select state logic
  $(document).on("click", ".ui-dropdown-select__link", function() {
    showTemplates(true);
    const optionSelectedUrl = $(this).data("href");
    const optionSelectedLabel = $(this).text();
    const currentDropdownLabelSelector = $(this)
      .closest(".ui-dropdown-select__wrapper")
      .find(".ui-dropdown-select__value span");

    const termsSelector = $(this)
      .closest(".ui-search-widget")
      .find(".auto-complete-input");

    const searchWidgetGroup = $(this)
      .closest(".ui-search-widget")
      .find(".ui-search-widget__group");

    const selectedStateValue = $(this).attr("data-value");

    if (!termsSelector.length && !!selectedStateValue) {
      searchWidgetGroup.removeClass("has-error");
    }

    const currentDropdownValueSelector = $(this)
      .closest(".ui-dropdown-select__wrapper")
      .find(".ui-dropdown-select__value");

    dropdownLinkSelector.removeClass(actionClasses.selected);
    $(this).addClass(actionClasses.selected);
    selectedOptionRedirectLinkSelector.attr("href", optionSelectedUrl);
    currentDropdownLabelSelector.text(optionSelectedLabel);

    if (selectedStateValue) {
      currentDropdownValueSelector.attr("data-value", $(this).data("value"));
    }

    if (window.matchMedia("(max-width: 960px)").matches) {
      dropdownWrapperSelector.removeClass(actionClasses.active);

      $(this)
        .closest(".ui-modal")
        .removeClass(actionClasses.animateSlideUp);

      $("body")
        .removeClass("is-ui-select-list")
        .removeClass("no-scrollable");
    }
  });

  handleCloseModalSelector.on("click", () => {
    pageSelector.removeClass(actionClasses.animateSlideHr);
  });

  changeOptionsFilter();
};
