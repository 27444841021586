import LazyLoad from 'vanilla-lazyload';

export const addedLazyLoadImages = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    load_delay: 0
  });

  if (lazyLoadInstance) {
    lazyLoadInstance.update();
  }
};
