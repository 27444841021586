export const commonLegacy = () => {
  (function() {
    var videoClickBlock = document.querySelector('[data-youtube-play]'),
        $videoBlock = document.querySelector('.youtube-video-player');

    if ($videoBlock != null) {
        function startPlayVideo() {

            var $play = this,
                $parent = $play.parentNode,
                $videoPlaceholder = document.querySelector('[data-youtube-placeholder]'),
                id = $play.getAttribute('data-youtube-id'),
                defaultParams = '?autoplay=1&wmode=transparent&rel=0',
                customParams = $play.getAttribute('youtube-params') || '',
                params = defaultParams + customParams,
                $iframe = document.createElement('iframe');

            $iframe.setAttribute('src', "//www.youtube.com/embed/" + id + params + "");
            $iframe.setAttribute('frameborder', '0');
            $iframe.setAttribute('allowfullscreen', '');
            $iframe.setAttribute('wmode', 'Opaque');

            $parent.classList.add('active');

            $videoPlaceholder.appendChild($iframe);

        }
        videoClickBlock.addEventListener('click', startPlayVideo, false);
        videoClickBlock.addEventListener('keydown', function (e) {
            if (e.key == 'Enter' && !e.target.parentElement.classList.contains('active')) {
                startPlayVideo.call(e.target);
            }
        });
    }
  })();

  var accordion = $('.js-accordion .accordion-item');
  var accordionItemTitle = $('.js-accordion .accordion-item__head');

  function openAccordion() {
    var animationTime = 0;
    accordion.not($(this)).removeClass('is-active');
    $(this).toggleClass('is-active');
    accordion.not($(this)).find('.accordion-item__body').slideUp(animationTime);
    $(this).find('.accordion-item__body').slideToggle(animationTime);
  }

  accordionItemTitle.on('focus', function(e) {
    accordionItemTitle.removeAttr("style");
  });

  accordion.on('click', function(e) {
    $(e.target).css("outline", "0");
    openAccordion.call(this);
  });

  accordion.on('keydown', function(e) {
    if (e.key == 'Enter') {
        openAccordion.call(e.target.parentNode);
    }
  });

  var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "other-browser";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "unknown-browser";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3));
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    },

    dataBrowser: [
        {string: navigator.userAgent, subString: "Edge", identity: "edge"},
        {string: navigator.userAgent, subString: "MSIE", identity: "ie"},
        {string: navigator.userAgent, subString: "Trident", identity: "ie"},
        {string: navigator.userAgent, subString: "Firefox", identity: "firefox"},
        {string: navigator.userAgent, subString: "Opera", identity: "opera"},
        {string: navigator.userAgent, subString: "OPR", identity: "opera"},

        {string: navigator.userAgent, subString: "Chrome", identity: "chrome"},
        {string: navigator.userAgent, subString: "Safari", identity: "safari"}
    ]
  };

  BrowserDetect.init();

  function getAndroidBrowser() {
    var ua = navigator.userAgent;

    if (ua.search(/Android/) > 0) {
        return ' android';
    }
    else {
        return '';
    }
  }

  function getIpadBrowser() {
    var ua = navigator.userAgent;

    if (ua.search(/iPad/) > 0) {
        return ' ipad';
    }
    else {
        return '';
    }
  }

  function iOS() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return iOS ? ' ios' : '';
  }

  document.documentElement.className += ' '+BrowserDetect.browser+''+getIpadBrowser()+''+getAndroidBrowser()+''+iOS();
}
